/** Colors */
// $brand-color-1: #3094c9 !default;
// $brand-color-2: #2d4152 !default;
// $brand-color-3: #f0f7fd !default;
// $brand-color-4: #525ddc !default;
// $brand-color-5: darken($brand-color-4, 50%) !default;

/** Box Model  */
$spacer: 2rem;

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Beard

$beard-reset: false;
$beard-base: false;

// Use btn for every button
:focus {
    outline: none;
}

.btn {
    font-family: $sans-serif;
    text-align: center;
    color: $white;
    background-color: $brand-color-4;
    border: 2px solid $brand-color-4;
    text-decoration: none;
    position: relative;
    transition: all 200ms ease-in-out;
    font-size: 16px;
    padding: 18px 40px;
    min-width: 190px;
    max-width: 260px;
    font-weight: 500;
    opacity: 1;
    display: inline-block;
    text-transform: uppercase;

    &:hover {
        border-color: $hover1;
        background: $hover1;
        cursor: pointer;
    }
}

.btn--wide {
    max-width: 281px;
}

.btn--outline {
    background: transparent;
    border: 2px solid $white;

    &:hover {
        background: $hover2;
        border: 2px solid $hover2;
    }
}

.link--offsite {
    position: relative;
    margin-left: 30px;

    &::after {
        position: absolute;
        content: '';
        background-size: contain;
        background-image: url('../images/external-link@2x.png');
        height: 18px;
        background-repeat: no-repeat;
        width: 12px;
        top: 0;
        left: -30px;
        transform: scale(0.7);
    }

    &:hover {
        &::after {
            opacity: 0.6;
        }
    }
}
// .link--offsite--mobile {
//   a {
//     position: relative;
//     margin-left: 30px;

//     &::after {
//       position: absolute;
//       content: '';
//       background-size: contain;
//       background-image: url('../images/external-link@2x.png');
//       height: 18px;
//       background-repeat: no-repeat;
//       width: 20px;
//       top: 0;
//       left: -30px;
//       transform: scale(0.7);
//     }

//     &:hover {
//       &::after {
//         opacity: 0.6;
//         filter: none !important;
//       }
//     }
//   }

//   .is-open & {
//     a {
//       &::after {
//         filter: grayscale(100%) brightness(9);
//       }
//     }
//   }
// }

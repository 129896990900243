/* stylelint-disable */

// Font Size Mixin
//-------------------------------------------------------------------------- */
@function font-size($size) {
    @return #{$size / 10}rem;
}

@mixin font-size($size: 12) {
    font-size: font-size($size);
}

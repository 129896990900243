/* stylelint-disable */

// Float Helpers
// ------------------------------------------------------------------------------------
.fl {
    float: left !important;
}

.fr {
    float: right !important;
}

.fn {
    float: none !important;
}

.finit {
    float: initial !important;
}

.finhe {
    float: inherit !important;
}

@if $beard-enable-responsive == true {
    @each $key, $breakpoint in $breakpoints {
        .#{$key}-fl {
            float: left !important;
        }

        .#{$key}-fr {
            float: right !important;
        }

        .#{$key}-fn {
            float: none !important;
        }

        .#{$key}-finit {
            float: initial !important;
        }

        .#{$key}-finhe {
            float: inherit !important;
        }
    }
}

/* stylelint-disable */

// Max Width Mixin
//-------------------------------------------------------------------------- */

@function max-width($size) {
    @return #{$size}px;
}

@mixin max-width($size: 12) {
    max-width: max-width($size);
    width: 100%;
}

@each $name, $amount in $max-width-values {
    .#{$name} {
        @include max-width($amount);
    }
}

/* stylelint-disable */

// Border Helpers
// -------------------------------------------------------------------------- */
@for $i from 0 through $beard-border-size-amount {
    .brdr#{$i} {
        border-style: solid !important;
        border-width: #{$i}px !important;
    }

    .brdr#{$i}--top {
        border-top-style: solid !important;
        border-top-width: #{$i}px !important;
    }

    .brdr#{$i}--right {
        border-right-style: solid !important;
        border-right-width: #{$i}px !important;
    }

    .brdr#{$i}--bottom {
        border-bottom-style: solid !important;
        border-bottom-width: #{$i}px !important;
    }

    .brdr#{$i}--left {
        border-left-style: solid !important;
        border-left-width: #{$i}px !important;
    }
}

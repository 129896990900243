/* stylelint-disable */

// Display Helpers
// ------------------------------------------------------------------------------------
.db {
    display: block;
}

.di {
    display: inline;
}

.dib {
    display: inline-block;
}

.dn {
    display: none;
}

.df {
    display: flex;
}

.full {
    display: block;
    width: 100%;
}

.w100 {
    width: 100%;
}

// Responsive Display Helpers
//-------------------------------------------------------------------------- */
@if $beard-enable-responsive == true {
    @each $key, $breakpoint in $breakpoints {
        .#{$key}-db {
            @include media($key) {
                display: block !important;
            }
        }

        .#{$key}-di {
            @include media($key) {
                display: inline !important;
            }
        }

        .#{$key}-dib {
            @include media($key) {
                display: inline-block !important;
            }
        }

        .#{$key}-dn {
            @include media($key) {
                display: none !important;
            }
        }

        .#{$key}-df {
            @include media($key) {
                display: flex !important;
            }
        }
    }
}

/* stylelint-disable */
// Position Helpers
//-------------------------------------------------------------------------
.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.fixed {
    position: fixed;
}

// Pin an item to the corners of the viewport
//-------------------------------------------------------------------------
.pin-top {
    top: 0;
}

.pin-right {
    right: 0;
}

.pin-bottom {
    bottom: 0;
}

.pin-left {
    left: 0;
}

.pin-edges {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

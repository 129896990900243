/* stylelint-disable */
// Overflow Helpers
//-------------------------------------------------------------------------- */
.ofh {
    overflow: hidden !important;
}

.ofx {
    overflow-x: scroll !important;
}

.ofy {
    overflow-y: scroll !important;
}

/* stylelint-disable */
// Add the zero spacing helper manually so it's last in the source order
@include new-spacing-helper(0);

// Spacing Helpers
//-------------------------------------------------------------------------- */
$position-units: (
    padding: 'p',
    margin: 'm'
);

// Generate Responsive Spacing Helpers
//-------------------------------------------------------------------------- */
@if $beard-enable-spacing-helpers == true {
    @each $id, $position in $position-units {
        @each $name, $i in $spacing-amounts {
            .#{$position}a#{$name} {
                #{$id}: #{$i}rem !important;
            }

            @if $beard-enable-responsive == true {
                @each $key, $breakpoint in $breakpoints {
                    .#{$key}-#{$position}a#{$name} {
                        @include media($key) {
                            #{$id}: #{$i}rem !important;
                        }
                    }
                }
            }

            .#{$position}t#{$name} {
                #{$id}-top: #{$i}rem !important;
            }

            @if $beard-enable-responsive == true {
                @each $key, $breakpoint in $breakpoints {
                    .#{$key}-#{$position}t#{$name} {
                        @include media($key) {
                            #{$id}-top: #{$i}rem !important;
                        }
                    }
                }
            }

            .#{$position}r#{$name} {
                #{$id}-right: #{$i}rem !important;
            }

            @if $beard-enable-responsive == true {
                @each $key, $breakpoint in $breakpoints {
                    .#{$key}-#{$position}r#{$name} {
                        @include media($key) {
                            #{$id}-right: #{$i}rem !important;
                        }
                    }
                }
            }

            .#{$position}b#{$name} {
                #{$id}-bottom: #{$i}rem !important;
            }

            @if $beard-enable-responsive == true {
                @each $key, $breakpoint in $breakpoints {
                    .#{$key}-#{$position}b#{$name} {
                        @include media($key) {
                            #{$id}-bottom: #{$i}rem !important;
                        }
                    }
                }
            }

            .#{$position}l#{$name} {
                #{$id}-left: #{$i}rem !important;
            }

            @if $beard-enable-responsive == true {
                @each $key, $breakpoint in $breakpoints {
                    .#{$key}-#{$position}l#{$name} {
                        @include media($key) {
                            #{$id}-left: #{$i}rem !important;
                        }
                    }
                }
            }

            .#{$position}v#{$name} {
                #{$id}-top: #{$i}rem !important;
                #{$id}-bottom: #{$i}rem !important;
            }

            @if $beard-enable-responsive == true {
                @each $key, $breakpoint in $breakpoints {
                    .#{$key}-#{$position}v#{$name} {
                        @include media($key) {
                            #{$id}-top: #{$i}rem !important;
                            #{$id}-bottom: #{$i}rem !important;
                        }
                    }
                }
            }

            .#{$position}h#{$name} {
                #{$id}-left: #{$i}rem !important;
                #{$id}-right: #{$i}rem !important;
            }

            @if $beard-enable-responsive == true {
                @each $key, $breakpoint in $breakpoints {
                    .#{$key}-#{$position}h#{$name} {
                        @include media($key) {
                            #{$id}-left: #{$i}rem !important;
                            #{$id}-right: #{$i}rem !important;
                        }
                    }
                }
            }
        }
    }
}

// Padding Removal Helpers
// ------------------------------------------------------------------------------------
.xp {
    padding: 0 !important;
}

.xpv {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.xph {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

// Margin Removal Helpers
// ------------------------------------------------------------------------------------
.xm {
    margin: 0 !important;
}

.xmv {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.xmh {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

@if $beard-enable-responsive == true and $beard-enable-spacing-helpers == true {
    @each $id, $position in $position-units {
        @each $key, $breakpoint in $breakpoints {
            // Generates classes like this: `sm-xm` and `md-xmh`
            .#{$key}-x#{$position} {
                @include media($key) {
                    #{$id}: 0 !important;
                }
            }

            // Vertical Spacing Removal
            .#{$key}-x#{$position}v {
                @include media($key) {
                    #{$id}-top: 0 !important;
                    #{$id}-bottom: 0 !important;
                }
            }

            // Horizontal Spacing Removal
            .#{$key}-x#{$position}h {
                @include media($key) {
                    #{$id}-left: 0 !important;
                    #{$id}-right: 0 !important;
                }
            }

            // Horizontal Centering
            .#{$key}-center {
                @include media($key) {
                    #{$id}-left: auto !important;
                    #{$id}-right: auto !important;
                }
            }
        }
    }
}

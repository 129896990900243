// Global Colors
// ------------------------------------------------------------------------------------ */
$white: #fff !default;
$black: #090d11 !default;
$color-body: #1f1f1f !default;
$offwhite: #f5f5f5 !default;

$beard-success-color: #4ab471 !default;
$beard-warning-color: #f3ae4e !default;
$beard-danger-color: #cf5c60 !default;

// Set Brand colors
//-------------------------------------------------------------------------- */
$brand-color-1: #151243 !default;
$brand-color-2: #2B2663 !default;
$brand-color-3: #8c897f !default;
$brand-color-4: #ed413e !default;
$brand-color-5:#6f6c63 !default;

// border colors
//-------------------------------------------------------------------------- */
$border1: #766f83;

// hover colors
//-------------------------------------------------------------------------- */
$hover1: #d43a37;
$hover2: $brand-color-5;
//-------------------------------------------------------------------------- */

$g05: #f5f5f5;
$g10: darken($white, 10%) !default;
$g20: darken($white, 20%) !default;
$g30: darken($white, 30%) !default;
$g40: darken($white, 40%) !default;
$g50: darken($white, 50%) !default;
$g60: darken($white, 60%) !default;
$g70: darken($white, 70%) !default;
$g75: darken($white, 75%) !default;
$g80: darken($white, 80%) !default;
$g90: darken($white, 90%) !default;

$beard-colors: (
    b: $black,
    w: $white,
    cb: $color-body,
    ow: $offwhite,
    1: $brand-color-1,
    2: $brand-color-2,
    3: $brand-color-3,
    4: $brand-color-4,
    5: $brand-color-5,
    success: $beard-success-color,
    warning: $beard-warning-color,
    danger: $beard-danger-color,
    g05: $g05,
    g10: $g10,
    g20: $g20,
    g30: $g30,
    g40: $g40,
    g50: $g50,
    g60: $g60,
    g70: $g70,
    g80: $g80,
    g90: $g90
) !default;

// Define brand color variables
//-------------------------------------------------------------------------- */
// Background colors
$bgb: map-get($beard-colors, b);
$bgw: map-get($beard-colors, w);
$bgcb: map-get($beard-colors, cb);
$bgow: map-get($beard-colors, ow);
$bg1: map-get($beard-colors, 1);
$bg2: map-get($beard-colors, 2);
$bg3: map-get($beard-colors, 3);
$bg4: map-get($beard-colors, 4);
$bg5: map-get($beard-colors, 5);

// Text colors
$tcb: map-get($beard-colors, b);
$tcw: map-get($beard-colors, w);
$tccb: map-get($beard-colors, cb);
$tcow: map-get($beard-colors, ow);
$tc1: map-get($beard-colors, 1);
$tc2: map-get($beard-colors, 2);
$tc3: map-get($beard-colors, 3);
$tc4: map-get($beard-colors, 4);
$tc5: map-get($beard-colors, 5);

// Border colors
$bcb: map-get($beard-colors, b);
$bcw: map-get($beard-colors, w);
$bccb: map-get($beard-colors, cb);
$bcow: map-get($beard-colors, ow);
$bc1: map-get($beard-colors, 1);
$bc2: map-get($beard-colors, 2);
$bc3: map-get($beard-colors, 3);
$bc4: map-get($beard-colors, 4);
$bc5: map-get($beard-colors, 5);

// Background Colors
// ------------------------------------------------------------------------------------ */
$bgg05: map-get($beard-colors, g05);
$bgg10: map-get($beard-colors, g10);
$bgg20: map-get($beard-colors, g20);
$bgg30: map-get($beard-colors, g30);
$bgg40: map-get($beard-colors, g40);
$bgg50: map-get($beard-colors, g50);
$bgg60: map-get($beard-colors, g60);
$bgg70: map-get($beard-colors, g70);
$bgg80: map-get($beard-colors, g80);
$bgg90: map-get($beard-colors, g90);

// Text Colors
// ------------------------------------------------------------------------------------ */
$tcg05: map-get($beard-colors, g05);
$tcg10: map-get($beard-colors, g10);
$tcg20: map-get($beard-colors, g20);
$tcg30: map-get($beard-colors, g30);
$tcg40: map-get($beard-colors, g40);
$tcg50: map-get($beard-colors, g50);
$tcg60: map-get($beard-colors, g60);
$tcg70: map-get($beard-colors, g70);
$tcg80: map-get($beard-colors, g80);
$tcg90: map-get($beard-colors, g90);

// Border Colors
// ------------------------------------------------------------------------------------ */
$bcg05: map-get($beard-colors, g05);
$bcg10: map-get($beard-colors, g10);
$bcg20: map-get($beard-colors, g20);
$bcg30: map-get($beard-colors, g30);
$bcg40: map-get($beard-colors, g40);
$bcg50: map-get($beard-colors, g50);
$bcg60: map-get($beard-colors, g60);
$bcg70: map-get($beard-colors, g70);
$bcg80: map-get($beard-colors, g80);
$bcg90: map-get($beard-colors, g90);

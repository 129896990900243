/* stylelint-disable */

// Text Aligment Helpers
// ------------------------------------------------------------------------------------
.tal {
    text-align: left !important;
}

.tac {
    text-align: center !important;
}

.tar {
    text-align: right !important;
}

@if $beard-enable-responsive == true {
    @each $key, $breakpoint in $breakpoints {
        .#{$key}-tal {
            @include media($key) {
                text-align: left !important;
            }
        }

        .#{$key}-tac {
            @include media($key) {
                text-align: center !important;
            }
        }

        .#{$key}-tar {
            @include media($key) {
                text-align: right !important;
            }
        }
    }
}

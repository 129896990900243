html {
    font-family: $sans-serif;
    font-size: 16px;
    font-weight: normal;
    color: $color-body;
}

h1,
.h1 {
    font-family: $sans-serif;
    font-weight: 500;
    font-size: 32px;
    line-height: 1.2;    
    color: $white;
    text-transform: uppercase;
}

h2,
.h2 {
    font-family: $sans-serif;
    font-weight: 300;
    font-size: 40px;
    line-height: 46px;

    @include media(md) {
        font-size: 48px;
        line-height: 52px;
    }
}

h3,
.h3 {
    font-family: $sans-serif;
    font-weight: 300;
    font-size: 36px;
    letter-spacing: -0.02em;
    line-height: 40px;
}

h4,
.h4 {
    font-family: $sans-serif;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.05em;
    line-height: 23px;
    // text-transform: uppercase;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
    color: $black;
    margin-bottom: 1.875rem;
    font-family: $sans-serif;
    // font-weight: 600;
    position: relative;

    &:first-child {
        margin-top: 0;
    }

    &:only-child {
        margin-bottom: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

p,
span {
    font-size: 16px;
    line-height: 1.66;
    font-family: $sans-serif;
    font-weight: normal;
    color: $black;
}

a,
span {
    font-size: 100%;
}

a {
    color: #016798;
    font-family: $sans-serif;
}

// p,
span,
div,
li {
    color: $black;
    font-family: $serif;
    font-size: 16px;

    @include media(lg) {
        font-size: 16px;
    }
}

.main,
.modal {
    ul,
    ol {
        padding-left: 20px;
        margin-top: 1rem;

        img {
            margin: 20px 0;
        }

        ul {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    }

    ol {
        list-style-type: decimal;
    }

    ul {
        list-style-type: none;
        margin-top: 20px;
        padding-left: 0;
        vertical-align: top;
        flex-direction: column;
        display: flex;

        li {
            max-width: 100%;
            display: inline-block;
            padding-right: 20px;
            margin-bottom: 20px;
            position: relative;
            padding-left: 30px;
            vertical-align: top;
            font-family: $sans-serif;
            font-size: 16px;
            line-height: 26px;
            font-weight: normal;
            color: $black;

            // @include media(lg) {
            //     width: 49%;

            //     ul li {
            //         width: 100%;
            //     }
            // }

            &::before {
                content: '';
                width: 4px;
                height: 4px;
                background-color: $black;
                left: 0;
                top: 12px;
                position: absolute;
                border-radius: 50%;
                transform: rotate(45deg);
            }
        }
    }

    a:not(.btn) {
        color: $brand-color-4;

        @include media(md) {
            &:hover,
            &:active {
                color: $hover1;
            }
        }
    }
}

.NormalList {
    li {
        width: 100% !important;
    }
}

hr {
    border: 0;
    height: 1px;
    background: linear-gradient(
        to left,
        rgba(#d9d9d6, 1),
        rgba(#d9d9d6, 1),
        rgba(#d9d9d6, 1)
    );
}

.Intro {
    font-size: 20px;
    letter-spacing: -0.01em;
    line-height: 36px;
}

.Footer{
    padding:70px;
    background:white;

    @include breakpoint(800px) {
        padding:30px 10px;
    }

    a {
        opacity:1;
        transition:.25s;
        &:hover {
            opacity:.7;
        }
    }

    .Footer-mainframe {
        padding-bottom:70px;

        @include breakpoint(800px) {
            padding-bottom:40px;
        }
    }

    .Footer-copyright {
        color: #909090;
        padding-left:20px;

        @include breakpoint(800px) {
            padding-left:0;
            margin-top:20px;
            display:block;
        }

    }

    .Footer-email {
        font-size:16px;
        line-height:1.5;
        color:$brand-color-2;

        &:hover {
            color: $brand-color-2;
            opacity: .7;
        }
    }

    .Footer-legal {
        font-size:12px;
    }
}
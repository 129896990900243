.Header {
    background:white;
    position:fixed;
    top:0;
    left:0;
    width:100%;
    z-index:100;
    transition:.25s;
    opacity:1;

    &.Header-inactive {
        opacity:0;
    }
}
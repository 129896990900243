.Hero {
    min-height:800px;
    background:black;
    padding-top:220px;
    padding-bottom:90px;
    background-position:center center;
    background-size:cover;
    position:relative;

    @include breakpoint(800px) {
        padding-top:150px;
        padding-bottom:40px
    }

    &:before {
        background:linear-gradient(90deg, rgba(4,0,44,0.67) 0%, rgba(4,0,44,0.1) 100%);
        position:absolute;
        width:100%;
        height:100%;
        content:'';
        left:0;
        top:0;
    }

    * {
        color:white;
    }

    .Hero-logo {
        margin-bottom:90px;
        transition:.25s;
        opacity:1;

        &.Hero-logo-inactive {
            opacity:0;
        }
    }

    .Hero-container {
        @include breakpoint(800px) {
            padding:0 20px;
        }
    }
}
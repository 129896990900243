.Overlay {
    position: relative;
    background-size: cover;
    background-position: center center;

    * {
        color: $white;
    }

    &::before {
        background-color:#000000;
        opacity: .4;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        position: absolute;
    }
}

.bg1 {
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    span,
    li {
        color: $white;
    }
}

.Cover {
    background-position: center center;
    background-size: cover;
}

a {
    text-decoration: none;
}

.LargeText {
    font-size: 22px;

    p {
        font-size: 22px;
    }
}

.grecaptcha-badge {
    visibility: hidden;
    display: none;
}

.dim {
    opacity: 1;
    transition: 0.25s opacity !important;
}

// .main {
//     > section:not(.Contact) {
//         &:last-child {
//             padding-bottom: 60px !important;

//             @include media(lg) {
//                 padding-bottom: 90px !important;
//             }
//         }
//     }
// }
.small-screen {
    @include breakpoint(240px) {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
}

// .IconCards.bgw + .bgw {
//   padding-top: 0 !important;
// }

.Editor.bgw + .bgw {
    padding-top: 48px !important;
}

html {
    background:white !important;
    overflow-x:hidden;
}

img {
    max-width:100%;
}

.Section-container {
    padding-left:10px;
    padding-right:10px;
}
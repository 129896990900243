/* stylelint-disable */

// Color Tools
//-------------------------------------------------------------------------- */
@mixin new-color($label, $def) {
    $map2: (
        $label: $def
    );
    $beard-colors: map-merge($beard-colors, $map2) !global;
}

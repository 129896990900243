/* stylelint-disable */

// Loop through Brand Colors and output classes for each type
//-------------------------------------------------------------------------- */

// Generate Helpers for each color
@each $id, $color in $beard-colors {
    .bg#{$id} {
        background-color: $color !important;
    }

    .tc#{$id} {
        color: $color !important;
    }

    .bc#{$id} {
        border-color: $color !important;
    }
}

// Generate helpers with hovers for each color
@each $id, $color in $beard-colors {
    .\:bg#{$id}:hover {
        background-color: $color !important;
    }

    .\:tc#{$id}:hover {
        color: $color !important;
    }

    .\:bc#{$id}:hover {
        border-color: $color !important;
    }
}

// Generate focus helpers for each color
@each $id, $color in $beard-colors {
    .\:\:bg#{$id}:focus {
        background-color: $color !important;
    }

    .\:\:tc#{$id}:focus {
        color: $color !important;
    }

    .\:\:bc#{$id}:focus {
        border-color: $color !important;
    }
}

// Generate active helpers for each color
@each $id, $color in $beard-colors {
    .\@bg#{$id}:active {
        background-color: $color !important;
    }

    .\@tc#{$id}:active {
        color: $color !important;
    }

    .\@bc#{$id}:active {
        border-color: $color !important;
    }
}

// Color Removal Helpers
// ------------------------------------------------------------------------- */
.xbg {
    background-color: transparent !important;
}

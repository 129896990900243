@mixin breakpoint($value) {
    @if $value == xs {
        @media (max-width: 480px) {
            @content;
        }
    }

    @else if $value == sm {
        @media (max-width: 768px) {
            @content;
        }
    }

    @else if $value == md {
        @media (max-width: 992px) {
            @content;
        }
    }

    @else if $value == lg {
        @media (max-width: 1024px) {
            @content;
        }
    }

    @else {
        @media (max-width: $value) {
            @content;
        }
    }
}

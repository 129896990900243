/* stylelint-disable */

// Tools to Generate Responsive Spacing Helpers
//-------------------------------------------------------------------------- */
@mixin new-spacing-helper($name, $size: null) {
    @if $size == null {
        $size: $name;
    }

    $new-helper: (
        $name: $size
    );
    $spacing-amounts: map-merge($spacing-amounts, $new-helper) !global;
}

// Build the initial list of spacing amounts
@for $i from 1 through $beard-spacing-helper-amount {
    @include new-spacing-helper($i);
}

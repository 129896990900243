/* stylelint-disable */

// Border Radius Helpers
// ------------------------------------------------------------------------------------
@for $i from 1 through $border-radius-amount {
    .br#{$i} {
        border-radius: #{$i}px !important;
    }

    .blr#{$i} {
        border-top-left-radius: #{$i}px !important;
        border-bottom-left-radius: #{$i}px !important;
    }

    .brr#{$i} {
        border-top-right-radius: #{$i}px !important;
        border-bottom-right-radius: #{$i}px !important;
    }

    .btlr#{$i} {
        border-top-left-radius: #{$i}px !important;
    }

    .btrr#{$i} {
        border-top-right-radius: #{$i}px !important;
    }

    .bbrr#{$i} {
        border-bottom-right-radius: #{$i}px !important;
    }

    .bblr#{$i} {
        border-bottom-left-radius: #{$i}px !important;
    }
}

// Circle Images
// ------------------------------------------------------------------------------------
.circle {
    border-radius: $circle-img-radius !important;
}

/* stylelint-disable */

@if $beard-base == true {
    // Base Elements
    //-------------------------------------------------------------------------- */
    *,
    *:before,
    *:after {
        box-sizing: inherit;
        -webkit-font-smoothing: antialiased;
    }

    html {
        font-size: 16px;
        box-sizing: border-box;
        height: 100%;
    }

    body {
        font-family: $sans-serif;
        min-height: 100%;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: $normal;
    }

    hr {
        background: none;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        margin: 0;
    }

    a {
        text-decoration: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    button {
        cursor: pointer;
        border: none;
    }

    input,
    textarea,
    button {
        outline: none;
    }

    section {
        overflow-x: hidden;
    }

    // Responsive Images
    //-------------------------------------------------------------------------- */
    img {
        max-width: 100%; // Make images fluid
        font-style: italic; // Offset alt text
        vertical-align: middle; // Removes bottom whitespace
    }

    // If width or height attributes are set, let's respect them
    //-------------------------------------------------------------------------- */
    img[width],
    img[height] {
        max-width: none;
    }
}

p {
    margin: 0 0 1rem 0;

    &:last-of-type {
        margin: 0 0 0 0;
    }
}
